import React, { useState, useEffect } from "react";
import { addSala } from "../helpers/rutaSalas";
import { getGrupos } from "../helpers/rutaGrupos";
import { getSalas } from "../helpers/rutaSalas";
//import { getIva } from "../helpers/rutaIva";
import "../css/addclienteform.css";

const AddSalaForm = ({ setShow }) => {
  //const id = JSON.parse(localStorage.getItem("id"));
  const [formValues, setFormValues] = useState({
    sala: "",
    turno: "",
    //activo: "",
    // usuario: id,
  });
  const handleChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    addSala(formValues).then((resp) => {
      console.log(resp);
      setFormValues({
        sala: "",
        turno: "",
      });
      alert("Grabado Exitoso !!!")
      //   setShow(false);
    });
  };

  const [salas, setSalas] = useState({
    data: {},
    loading: true,
  });
  useEffect(() => {
    getSalas().then((salas) => {
      setSalas({
        data: salas,
        loading: false,
      });
    });
  }, []);

  // const [grupos, setGrupos] = useState({
  //   data: {},
  //   loading: true,
  // });
  // useEffect(() => {
  //   getGrupos().then((grupos) => {
  //     setGrupos({
  //       data: grupos,
  //       loading: false,
  //     });
  //   });
  // }, []);

  return (
    <>
      {!salas.loading && (
        <div className="container">
          {/* {localidades.data.localidades.map((localidad) => (
            <h3>{localidad.localidad}</h3>
          ))} */}

          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="form-group mt-3 col-sm-6">
                <label className="">Salas</label>
                <input
                  type="text"
                  className="form-control"
                  name="sala"
                  maxLength="30"
                  required
                  value={formValues.sala}
                  onChange={handleChange}
                />
                {console.log(formValues.sala)}
              </div>

              <div className="form-group mt-3 col-sm-2">
                  <label className="">Turno</label>
                  <select
                    className="form-control"
                    name="turno"
                    value={formValues.sede}
                    onChange={handleChange}
                    required
                  >
                    <option selected value="">
                      Elija opción
                    </option>
                    <option>Mañana</option>
                    <option>Siesta</option>
                    <option>Tarde</option>
                  </select>
                </div>

              {/* <div className="form-group mt-3 col-sm-4">
                <label className="">Grupo</label>
                <select
                  className="form-control"
                  name="grupo"
                  value={formValues.grupo}
                  onChange={handleChange}
                  required
                >
                  <option selected value="">
                    Elija opción
                  </option>
                  {grupos.data.grupos.map((grupo) => (
                    <option value={grupo._id}>{grupo.grupo}</option>
                  ))}
                </select>
              </div> */}
              
            </div>

            <div>
              <button
                type="submit"
                className="btn btn-dark mt-5 mb-3"
                id="button"
              >
                Guardar
              </button>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default AddSalaForm;
