import React, { useState, useEffect } from "react";
import { modifCuenta } from "../helpers/rutaCuentas";
import { Modal, Button } from "react-bootstrap";
import { getCuentas } from "../helpers/rutaCuentas";
import { getGrupos } from "../helpers/rutaGrupos";

const ModalFormCuenta = ({ cuenta, handleClose }) => {
  console.log(cuenta);
  const id = localStorage.getItem("id");
  const [formValues, setFormValues] = useState({
    cuenta: cuenta.cuentas.cuenta,
    grupo: cuenta.cuentas.grupo,
    valor: cuenta.cuentas.valor,
    // usuario: id,
  });

  const handleChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = (e) => {
    console.log(cuenta.cuentas._id);
    e.preventDefault();
    modifCuenta(formValues, cuenta.cuentas._id).then((respuesta) => {
      console.log(respuesta);
      alert("Cambio Exitoso !!!")
      handleClose();
    });
  };

  const [cuentas, setCuentas] = useState({
    data: {},
    loading: true,
  });
  useEffect(() => {
    getCuentas().then((cuentas) => {
      setCuentas({
        data: cuentas,
        loading: false,
      });
    });
  }, []);

  const [grupos, setGrupos] = useState({
    data: {},
    loading: true,
  });
  useEffect(() => {
    getGrupos().then((grupos) => {
      setGrupos({
        data: grupos,
        loading: false,
      });
    });
  }, []);

  return (
    <>
      {!cuentas.loading && !grupos.loading && (
        <form onSubmit={handleSubmit}>
          <Modal.Body>
            <div className="form-group mt-3">
              <label>Cuenta</label>
              <input
                rows="1"
                type="text"
                className="form-control"
                name="cuenta"
                maxLength="50"
                minLength="5"
                required
                value={formValues.cuenta}
                onChange={handleChange}
              />
            </div>

            <div className="form-group mt-3">
              <label>Grupo</label>
              <select
                className="form-control"
                name="grupo"
                value={formValues.grupo}
                onChange={handleChange}
                required
              >
                <option selected value="">
                  Elija la opción adecuada
                </option>
                {grupos.data.grupos.map((grupo) => (
                  <option value={grupo._id}>{grupo.grupo}</option>
                ))}
              </select>
            </div>

            <div className="form-group mt-3">
              <label>Valor</label>
              <input
                rows="1"
                type="text"
                className="form-control"
                name="valor"
                maxLength="2"
                required
                value={formValues.valor}
                onChange={handleChange}
              />
            </div>

          </Modal.Body>
          <Modal.Footer>
            <Button type="submit" variant="dark">
              Guardar
            </Button>
          </Modal.Footer>
        </form>
      )}
      ;
    </>
  );
};

export default ModalFormCuenta;
