import React, { useState, useEffect } from "react";
import { modifGasto } from "../helpers/rutaGastos"; 
import { addGastopagado } from "../helpers/rutaGastopagados";
import { Modal, Button } from "react-bootstrap";
import { getGastos } from "../helpers/rutaGastos";
import { getProducservs } from "../helpers/rutaProducservs";
import { getProveedores } from "../helpers/rutaProveedores";
import moment from 'moment';

const ModalFormGastoActual = ({ gasto, handleClose }) => {
  const id = localStorage.getItem("id");

  // Setea la fecha actual en caso de que no haya fecha de pago
  const [formValues, setFormValues] = useState({
    nrocomp: gasto.gastos.nrocomp,
    codprov: gasto.gastos.codprov,
    codprod: gasto.gastos.codprod,
    fechacomp: gasto.gastos.fechacomp,
    monto: gasto.gastos.monto,
    tipo: gasto.gastos.tipo,
    fechapago: gasto.gastos.fechapago ? gasto.gastos.fechapago : moment().format('YYYY-MM-DD'),
    montopagado: gasto.gastos.montopagado, // Este será de solo lectura
  });

  // Nuevo campo para el monto que se aplicará al pago
  const [nuevoMontoAplicado, setNuevoMontoAplicado] = useState("");

  const handleChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  // Manejar el cambio en el nuevo campo "Monto Aplicado"
  const handleNuevoMontoChange = (e) => {
    setNuevoMontoAplicado(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Incrementar monto pagado con el nuevo valor ingresado
    const nuevoMontoAplicadoNumber = parseFloat(nuevoMontoAplicado) || 0;
    const montoActual = parseFloat(gasto.gastos.montopagado) || 0;
    const montoTotal = montoActual + nuevoMontoAplicadoNumber;

    // Modificar el gasto existente con el nuevo monto pagado
    await modifGasto({ ...formValues, montopagado: montoTotal }, gasto.gastos._id);
    
    // Crear nuevo modelo de datos gastospagados
    const nuevoGastoPagado = {
      nrocomp: formValues.nrocomp,
      fechapago: moment(formValues.fechapago).format('YYYY-MM-DD'),
      montoaplicado: nuevoMontoAplicadoNumber,
      gastoReferencia: gasto.gastos._id, // ID del gasto original como referencia
    };

    await addGastopagado(nuevoGastoPagado); // Asegúrate de que esta función esté implementada

    handleClose();
  };

  const formatDatePago = moment(formValues.fechapago).format('YYYY-MM-DD');

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Modal.Body>
          <div className="form-group mt-3">
            <label>Fecha Pago</label>
            <input
              type="date"
              className="form-control"
              name="fechapago"
              required
              value={formatDatePago}
              onChange={handleChange}
            />
          </div>

          <div className="form-group mt-3">
            <label>Monto Comprobante</label>
            <input
              type="text"
              className="form-control"
              name="monto"
              value={formValues.monto}
              readOnly // Hacemos que el campo sea de solo lectura
            />
          </div>

          <div className="form-group mt-3">
            <label>Monto Pagado a la Fecha</label>
            <input
              type="text"
              className="form-control"
              name="montopagado"
              value={formValues.montopagado}
              readOnly // Hacemos que el campo sea de solo lectura
            />
          </div>

          <div className="form-group mt-3">
            <label>Monto que pagara ahora</label>
            <input
              type="text"
              className="form-control"
              name="nuevoMontoAplicado"
              value={nuevoMontoAplicado}
              onChange={handleNuevoMontoChange}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit" variant="dark">
            Guardar
          </Button>
        </Modal.Footer>
      </form>
    </>
  );
};

export default ModalFormGastoActual;


