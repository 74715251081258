import React, { useState, useEffect } from "react";
import { modifSala } from "../helpers/rutaSalas";
import { Modal, Button } from "react-bootstrap";
import { getSalas } from "../helpers/rutaSalas";
import { getGrupos } from "../helpers/rutaGrupos";

const ModalFormSala = ({ sala, handleClose }) => {
  console.log(sala);
  const id = localStorage.getItem("id");
  const [formValues, setFormValues] = useState({
    sala: sala.salas.sala,
    turno: sala.salas.turno,
    // usuario: id,
  });

  const handleChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = (e) => {
    console.log(sala.salas._id);
    e.preventDefault();
    modifSala(formValues, sala.salas._id).then((respuesta) => {
      console.log(respuesta);
      handleClose();
    });
  };

  const [salas, setSalas] = useState({
    data: {},
    loading: true,
  });
  useEffect(() => {
    getSalas().then((salas) => {
      setSalas({
        data: salas,
        loading: false,
      });
    });
  }, []);

  const [grupos, setGrupos] = useState({
    data: {},
    loading: true,
  });
  useEffect(() => {
    getGrupos().then((grupos) => {
      setGrupos({
        data: grupos,
        loading: false,
      });
    });
  }, []);

  return (
    <>
      {!salas.loading && !grupos.loading && (
        <form onSubmit={handleSubmit}>
          <Modal.Body>
            <div className="form-group mt-3">
              <label>Sala</label>
              <input
                rows="1"
                type="text"
                className="form-control"
                name="sala"
                maxLength="50"
                minLength="5"
                required
                value={formValues.sala}
                onChange={handleChange}
              />
            </div>

            <div className="form-group mt-3">
              <label className="">Turno</label>
                <select
                  className="form-control"
                  name="turno"
                  value={formValues.turno}
                  onChange={handleChange}
                  required
                >
                  <option selected value="">
                    Elija opción
                  </option>
                  <option>Mañana</option>
                  <option>Siesta</option>
                  <option>Tarde</option>
                </select>
             </div>

          </Modal.Body>
          <Modal.Footer>
            <Button type="submit" variant="dark">
              Guardar
            </Button>
          </Modal.Footer>
        </form>
      )}
      ;
    </>
  );
};

export default ModalFormSala;
