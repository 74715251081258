import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
//import Error404 from "./pages/Error404";
import Home from "./pages/Home";
import HomeImpresion from "./pages/HomeImpresion";
import HomeAltas from "./pages/HomeAltas";
import Layout from "./components/Layout";
import Login from "./pages/Login";
import Admin from "./pages/Admin";
import Comandas from "./pages/Comandas";
//import Camiones from "./pages/Camiones";
//import Mapas from "./pages/Mapas";
//import Stocks from "./pages/Stocks";
//import StocksPrev from "./pages/StocksPrev";
import Alumnos from "./pages/Alumnos";
import Docentes from "./pages/Docentes";
import Grupos from "./pages/Grupos";
import Cuentas from "./pages/Cuentas";
import Salas from "./pages/Salas";
import Gastos from "./pages/Gastos";
import Clientes from "./pages/Clientes";
//import ClientesPrev from "./pages/ClientesPrev";
import Proveedores from "./pages/Proveedores";
//import Localidades from "./pages/Localidades";
//import Empresas from "./pages/Empresas";
import Producservs from "./pages/Producservs";
//import Rutas from "./pages/Rutas";
//import Rubros from "./pages/Rubros";
//import Marcas from "./pages/Marcas";
import Precios from "./pages/Precios";
import Remitos from "./pages/Remitos";
import InformeAlumnos from "./pages/InformeAlumnos";
import InformeDocentes from "./pages/InformeDocentes";
import InformeGastos from "./pages/InformeGastos";
import InformeEgresos from "./pages/InformeEgresos";
import InformeComandas from "./pages/InformeComandas";
//import InformeImpresion from "./pages/InformeImpresion";
//import InformeGestion from "./pages/InformeGestion";
//import InformePreventas from "./pages/InformePreventas";
//import InformeRemitos from "./pages/InformeRemitos";
//import InformeStock from "./pages/InformeStock";
//import InformeOrdenAPreparar from "./pages/InformeOrdenAPreparar";
//import InformeHojaRuta from "./pages/InformeHojaRuta";

const App = () => {
  return (
    <>
      <Router>
        <Layout>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/HomeImpresion" component={HomeImpresion} />
            <Route exact path="/HomeAltas" component={HomeAltas} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/Alumnos" component={Alumnos} />
            <Route exact path="/Comandas" component={Comandas} />
            <Route exact path="/Docentes" component={Docentes} />
            <Route exact path="/Grupos" component={Grupos} />
            <Route exact path="/Cuentas" component={Cuentas} />
            <Route exact path="/Salas" component={Salas} />
            <Route exact path="/Producservs" component={Producservs} />
            <Route exact path="/Precios" component={Precios} />
            <Route exact path="/Gastos" component={Gastos} />
            <Route exact path="/Clientes" component={Clientes} />
            <Route exact path="/Proveedores" component={Proveedores} />
            <Route exact path="/InformeAlumnos" component={InformeAlumnos} />
            <Route exact path="/InformeDocentes" component={InformeDocentes} />
            <Route exact path="/InformeGastos" component={InformeGastos} />
            <Route exact path="/InformeEgresos" component={InformeEgresos} />
            <Route exact path="/InformeComandas" component={InformeComandas} />
            <Route exact path="/Remitos" component={Remitos} />
            <Route exact path="/admin" component={Admin} />
          </Switch>
        </Layout>
      </Router>
    </>
  );
};

export default App;

